/* Header.css */
.header {
  background-color: transparent; /* Remove the linear gradient */
  color: rgb(129, 185, 254);
  padding: 20px;
}

.menu-list {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
}

.menu-list li {
  margin: 0 15px;
}

.menu-list a {
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-weight: bold;
  padding: 10px 20px; /* Add padding to create spacing around the text */
  display: inline-block; /* Ensure the link takes the width of its content */
  position: relative;
  border-radius: 20px; /* Adjust the value to create a rounded shape */
  transition: background-color 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(#ecf8fa, #cbd5d6); 
}


.menu-list a:hover {
  color: #ffffff;
  
}

@media screen and (max-width: 768px) {
  .menu-list {
    flex-direction: column; /* Stack menu items vertically on smaller screens */
    text-align: center; /* Center-align the menu items */
  }

  .menu-list li {
    margin: 10px 0; /* Add spacing between vertically stacked menu items */
  }
}

